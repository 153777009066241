.one{
    animation:oneAn 2s linear infinite;
    position: absolute;
    top: -80px;
    left:-50px;
}
@keyframes oneAn{
    0%,100%{
        top: -80px;
    }
    50%{
        top:100px;
    }
}
.onelore{
    animation:oneAnlore 5s linear infinite;
    position: absolute;
    top: -10px;
    left:-10px;
}
@keyframes oneAnlore{
    0%,100%{
        top: -80px;
    }
    50%{
        top:20px;
    }
}
.two{
    animation:twoAn 4s linear infinite;
    position: absolute;
     bottom: -850px;;
    right: -50px;;
}
@keyframes twoAn{
    0%,100%{
        top: -100px;
    }
    50%{
        top:20px;
    }
}
.twolore{
    animation:twoAnlore 3s linear infinite;
    position: absolute;
     bottom: 600px;;
    right: 0px;;
}
@keyframes twoAnlore{
    0%,100%{
        top: -280px;
    }
    50%{
        top:300px;
    }
}
.pd-20{
  padding-left: 12rem;
  padding-right: 12rem;
}

.three{
    animation:threeAn 3s linear infinite;
    position: absolute;
    bottom: 0px;
    left:0px;
}
@keyframes threeAn{
    0%,100%{
        bottom: 20px;
    }
    50%{
        bottom:-40px;
    }
}
.four{
     animation:fourAn 6s linear infinite;
    position: absolute;
    top:-100px;
    left:400px;
}
@keyframes fourAn{
    0%,100%{
        top: -120px;
    }
    50%{
        top:10px;
    }
}
