@tailwind base;
@tailwind components;
@tailwind utilities;

.bgcolor{
  background: linear-gradient(90deg, rgba(195,192,237,1) 0%, rgba(195,135,135,1) 0%, rgba(142,103,134,1) 100%);

}
/*Roadmap text*/
.text-left{
  text-align: left;
}
.text-italic{
  font-size: 1.4rem;
}
.logo{
  height: 60px !important;
}
.mb30{
margin-bottom: 30px;
}
.mt30{
margin-top: 30px;
}

.padall{

  padding-left: 60px;
  padding-right: 60px;
  padding-top: 40px;
  padding-bottom: 40px;

}
.main-banner__video{
/*  height: 600px;*/
filter: brightness(40%);
}

.bgradient {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;
}
.bgradient2 {
	background: linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;
}
.lore{
  padding: 50px;
}
.pd-20{
  padding-left: 12rem;
  padding-right: 12rem;
}
.link_text{
  text-decoration: underline;
}
.italic_text{
  font-style: italic;
}
/*flip head text animation*/

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@keyframes flipV {
   50% {
    transform: rotateX(180deg);
  } to {
    transform: rotateX(360deg);
  }
} @keyframes flipH {
   0% {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.fliptext {
  cursor:pointer;
  animation: flipH 1s linear infinite ;
  display: inline-block
}
.fliptextV {
  cursor:pointer;
  animation: flipV 1s linear infinite ;
  display: inline-block
}
